<template>
  <div
    class="rounded-full flex items-center justify-center fixed z-30 cursor-pointer shadow-xl shadow-slate-900/30 md:right-3 transition-all lg:hidden"
    :class="{
      'w-10 h-10  bg-slate-600 lg:hover:bg-slate-500 focus:bg-slate-500 bottom-[14px] right-[8px]': chatState === 'minified' || chatState === 'fullscreen' || chatState === 'embedded',
      'w-12 h-12 bg-blue-500 lg:hover:bg-blue-400 focus:bg-blue-400 bottom-8 lg:bottom-20 right-4': chatState === 'hidden',
      'xl:hidden' : embeddedBreakpointView,
      '!right-2 md:!right-3' : showGameContainer,
      '!bottom-2': showGameContainer && chatState === 'hidden',
      '!w-12 !h-12 md:!w-16 md:!h-16' : showGameContainer && ['hidden'].includes(chatState)
    }"
    @click.prevent="emits('toggle-chat-display')"
  >
    <span v-if="chatState === 'minified' || chatState === 'fullscreen' || chatState === 'embedded'" class="icon-ico-x text-white text-md"/>
    <span v-if="chatState === 'hidden'" class="icon-ico-chat text-white text-3xl"/>

    <transition name="bottom-pop">
      <BadgeNotification
        v-if="unseenCount > 0 && chatState === 'hidden'"
        :key="`counter-${unseenCount}`"
        :count="unseenCount"
        theme="bg-red-500"
        class="absolute -top-1 left-1 shadow-xl shadow-slate-900/80"
      />
    </transition>
  </div>
</template>

<script setup>
defineProps({
  chatState: {
    type: String,
    default: null,
  },
  embeddedBreakpointView: {
    type: Boolean,
    default: false,
  },
  showGameContainer: {
    type: Boolean,
    default: false,
  },
  unseenCount: {
    type: Number,
    default: null,
  },
});

const emits = defineEmits([
  'toggle-chat-display',
]);
</script>
