<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <button
      v-for="item in items"
      :key="item.icon"
      class="flex items-center nav-button rounded-md w-full relative"
      :class="{ 'active': item.isActive }"
      size="2xs"
      @click.prevent.stop="item.action()"
    >
      <SvgIcon
        :icon="item.icon"
        height="20"
        width="20"
        class="icon"
        :class="item.color"
      />
      <div
        v-if="navState === 'full'"
        class="label !font-bold inline-flex justify-between w-full"
      >
        <span :class="item.isActive ? 'text-white' : item.color">{{ item.label }}</span>
        <span v-if="item.leaderboardName && !loading" class="my-auto text-orange-dark h-3 text-xs">{{ days > 0 ? `${days}d` : 'Today' }}</span>
      </div>
      <NavigationToolTip :label="item.label"/>
    </button>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, } = storeToRefs(uiStore);

const { sportsBettingRedirect, } = useNavigation();

const route = useRoute();
const { $api, } = useNuxtApp();

const days = ref(0);
const loading = ref(true);

const items = computed(() => [
  {
    icon: 'mwin',
    color: 'gold',
    leaderboardName: 'WeeklyWagering',
    label: '$100k Weekly Race',
    isActive: route.path.includes('weekly-race'),
    action: () => sportsBettingRedirect('/weekly-race'),
  },
  {
    icon: 'tag',
    label: 'Promotions',
    color: 'purple',
    isActive: route.path.includes('promotions') && !route.path.includes('salon-rouge'),
    action: () => sportsBettingRedirect('/promotions'),
  },
]);

async function fetchMonthlyDayCountdown() {
  loading.value = true;
  try {
    const response = await $api(`/leaderboard/schedule/${items.value[0].leaderboardName}/latest/1`, { method: 'GET', });

    const endTime = new Date(response[0].endTime).getTime();
    const now = new Date().getTime();
    const distance = endTime - now;

    days.value = Math.floor((distance % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
  } catch {
    // empty
  } finally {
    loading.value = false;
  }
}

fetchMonthlyDayCountdown();
</script>
<style lang="scss" scoped>
// Hardcoded cos tailwind reasons
.purple {
  color: #CB6CE6 !important;
}
.gold {
  color: #FFC100 !important;
}
</style>
